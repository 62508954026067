exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-game-[id]-tsx": () => import("./../../../src/pages/game/[id].tsx" /* webpackChunkName: "component---src-pages-game-[id]-tsx" */),
  "component---src-pages-games-bigbang-tsx": () => import("./../../../src/pages/games/bigbang.tsx" /* webpackChunkName: "component---src-pages-games-bigbang-tsx" */),
  "component---src-pages-games-blackhole-tsx": () => import("./../../../src/pages/games/blackhole.tsx" /* webpackChunkName: "component---src-pages-games-blackhole-tsx" */),
  "component---src-pages-games-index-tsx": () => import("./../../../src/pages/games/index.tsx" /* webpackChunkName: "component---src-pages-games-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manage-banks-tsx": () => import("./../../../src/pages/manage/banks.tsx" /* webpackChunkName: "component---src-pages-manage-banks-tsx" */),
  "component---src-pages-manage-games-tsx": () => import("./../../../src/pages/manage/games.tsx" /* webpackChunkName: "component---src-pages-manage-games-tsx" */),
  "component---src-pages-manage-users-tsx": () => import("./../../../src/pages/manage/users.tsx" /* webpackChunkName: "component---src-pages-manage-users-tsx" */),
  "component---src-pages-manage-wares-tsx": () => import("./../../../src/pages/manage/wares.tsx" /* webpackChunkName: "component---src-pages-manage-wares-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-socket-test-tsx": () => import("./../../../src/pages/socket-test.tsx" /* webpackChunkName: "component---src-pages-socket-test-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

