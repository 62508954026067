import { Group, Image } from "@mantine/core"
import { Link } from "gatsby";
import React from "react";
import { Profile } from "./Profile";
import { ThemeSwitcher } from "./ThemeSwitcher";

export function Right() {
    return <Group justify='end' w={{ base: 'auto', md: '30%' }}>
        {/* <Group visibleFrom="md">
            <Link to='/'><Image src={'/img/bidv.png'} height={33} /></Link>
            <Link to='/'><Image src={'/img/ssi.png'} height={33} /></Link>
        </Group> */}
        {/* <ThemeSwitcher /> */}
        <Profile />
    </Group>
}